// Home.js
import './Home.css';
import lc from './lcwearinghat.jpg';
import React from 'react';

function Home() {
  return (
    <div className="image-container">
        <img src={lc} alt="lechen" className="full-screen-image" />
    </div>
  );
}

export default Home;